
import { ref, reactive, defineComponent, Ref, watch, computed, onMounted } from 'vue';
import type { FormInstance } from 'element-plus';
import Title from "@/components/Title.vue"
import Button from "@/components/Button.vue"
import { useRouter } from 'vue-router';
import { Delete, Plus } from '@element-plus/icons-vue';
import Api from '@/services/Api';
import useAlert from '@/composables/Alert'

interface JiraInterface{
  key: string | number | null,
  tipo: string | null,
}
interface Projeto {
  key: string;
  name: string;
}

export default defineComponent({
  name: "AlertSound",
    components: {Title,Button},
  setup() {
    const router = useRouter();
    const { showTimeAlert } = useAlert();
    const arraySounds: Ref<any> = ref(['Piu', 'Celular', 'Email']);
    const sound: Ref<any> = ref(localStorage.getItem('SoundOption') || 'Piu')

    function saveOption(selectedCase: string) {
      switch (selectedCase) {
        case 'Piu':
          localStorage.setItem('Sound', 'sound/pew_pew.mp3');
          localStorage.setItem('SoundOption', 'Piu')
          break;
        case 'Celular':
          localStorage.setItem('Sound', 'sound/bereal.mp3');
          localStorage.setItem('SoundOption', 'Celular')
          break;
        case 'Email':
          localStorage.setItem('Sound', 'sound/sound.mp3');
          localStorage.setItem('SoundOption', 'Email')
          break;
        default:
          localStorage.setItem('Sound', 'sound/pew_pew.mp3');
          localStorage.setItem('SoundOption', 'Piu')
          break;
      }
    }

    function goBack() {
      history.back();
    }

    watch(()=> sound.value, ()=> saveOption(sound.value))

    onMounted(()=> {})

    return {
      arraySounds,
      sound, 
      goBack
    }
  },
});
